import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { HelmetProvider } from "react-helmet-async";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";

import RootLayout from "./RootLayout";
import AccountPage from "./AccountPage";
import DashboardPage from "./DashboardPage";

const defaultRoutes = [
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
      {
        path: "/account",
        element: <AccountPage />,
      },
    ],
  },
];

const router = createBrowserRouter([...defaultRoutes]);

const defaultTheme = createTheme();

export default function App() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_TENANT_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <HelmetProvider>
          <RouterProvider router={router} />
        </HelmetProvider>
      </ThemeProvider>
    </Auth0Provider>
  );
}
