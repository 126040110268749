import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";

export const drawerWidth = 240;

const Content = ({ children }) => {
  return (
    <>
      <Toolbar />
      <Divider />
      {children}
    </>
  );
};

const Drawer = (props) => {
  const { children, isOpen, onOpen } = props;

  const handleDrawerToggle = () => {
    onOpen();
  };

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
      }}
    >
      <MuiDrawer
        variant="temporary"
        open={isOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        <Content>{children}</Content>
      </MuiDrawer>
      <MuiDrawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open={true}
      >
        <Content>{children}</Content>
      </MuiDrawer>
    </Box>
  );
};

export default Drawer;
