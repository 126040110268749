import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

import Skeleton from "@mui/material/Skeleton";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ListItemIcon from "@mui/material/ListItemIcon";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";

import LoginButton from "./LoginButton";

export default function UserProfile() {
  // TODO: do something with `user` object
  const { isAuthenticated, isLoading, logout, user } = useAuth0();

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  if (isLoading) {
    return <Skeleton variant="circle" width={40} height={40} />;
  }

  if (!isAuthenticated) {
    return <LoginButton />;
  }

  console.group("UserProfile:");
  console.log("user:", user);
  console.groupEnd();

  return (
    <Box sx={{ flexGrow: 0 }}>
      <IconButton color="inherit" sx={{ mr: 2 }}>
        <Badge badgeContent={4} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt={user.name} src={user.picture} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem key="account" component={Link} to="/account">
          <ListItemIcon>
            <Avatar
              alt={user.name}
              src={user.picture}
              sx={{ width: 22, height: 22 }}
            />
          </ListItemIcon>
          <Typography textAlign="center">Account</Typography>
        </MenuItem>
        <MenuItem key="dashboard" component={Link} to="/">
          <ListItemIcon>
            <DashboardIcon fontSize="small" />
          </ListItemIcon>
          <Typography textAlign="center">Dashboard</Typography>
        </MenuItem>
        <Divider />
        <MenuItem key="logout" onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}
