import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Copyright from "./Copyright";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import UserProfile from "./UserProfile";
import Loading from "./Loading";
import Drawer, { drawerWidth } from "./Drawer";
import DrawerItem from "./DrawerItem";

// ApplicationList
import List from "@mui/material/List";
import AppsIcon from "@mui/icons-material/Apps";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import SoupKitchenIcon from "@mui/icons-material/SoupKitchen";

const ApplicationList = () => {
  return (
    <List component="nav">
      <DrawerItem name="Applications" iconComponent={<AppsIcon />}>
        <DrawerItem
          name="Recipe"
          iconComponent={<SoupKitchenIcon />}
          onClick={() => {
            console.log("hello recipe");
          }}
        />
      </DrawerItem>
      <DrawerItem
        name="Integrations"
        iconComponent={<AutoAwesomeMotionIcon />}
      />
    </List>
  );
};

const RootLayout = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (isLoading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Loading />
          <Typography>Loading...</Typography>
        </Box>
      </Container>
    );
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return (
      <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Loading />
          <Typography>Redirecting...</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>Perrotte - Dashboard</title>
      </Helmet>
      <Box sx={{ display: "flex" }}>
        {/* APP BAR CONFIG */}
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Perrotte Dashboard
            </Typography>
            <UserProfile />
          </Toolbar>
        </AppBar>
        {/* DRAWER CONFIG */}
        <Drawer isOpen={mobileOpen} onOpen={handleDrawerToggle}>
          <ApplicationList />
        </Drawer>
        {/* PAGE CONTENT */}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Container maxWidth="lg" sx={{ mb: 4 }}>
            <Toolbar /> {/* here for padding */}
            <Outlet />
          </Container>
          <Copyright sx={{ pt: 2, pb: 4 }} />
        </Box>
      </Box>
    </>
  );
};

export default RootLayout;
