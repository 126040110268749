import { Children, useState, cloneElement } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";

import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const DrawerItem = (props) => {
  const {
    iconComponent,
    key,
    name,
    text,
    children,
    onClick,
    ...inheritedProps
  } = props;

  const [open, setOpen] = useState(true);

  const handleExpandClick = () => {
    setOpen(!open);
  };

  const handleItemClick = () => {
    onClick && onClick();
  };

  if (children) {
    return (
      <>
        <ListItem key={key || name} disablePadding>
          <ListItemButton onClick={handleExpandClick}>
            <ListItemIcon>{iconComponent}</ListItemIcon>
            <ListItemText primary={text || name} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Children.map(children, (child) => {
              return cloneElement(child, {
                sx: { pl: 3 },
              });
            })}
          </List>
        </Collapse>
      </>
    );
  } else {
    return (
      <ListItem key={key || name} disablePadding {...inheritedProps}>
        <ListItemButton onClick={handleItemClick}>
          <ListItemIcon>{iconComponent}</ListItemIcon>
          <ListItemText primary={text || name} />
        </ListItemButton>
      </ListItem>
    );
  }
};

export default DrawerItem;
