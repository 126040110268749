import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export default function Copyright(props) {
  const buildVersion = process.env.REACT_APP_BUILD_COMMIT;
  const short = buildVersion.slice(0, 7);

  return (
    <Container {...props}>
      <Typography variant="body2" color="text.secondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://mui.com/">
          Orionsbelt
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        {`Build: ${short}`}
      </Typography>
    </Container>
  );
}
